import pages from "./pages";
import { ROLES } from "./common";

const ALLOW_ALL = [ROLES.ACCOUNT_ADMIN, ROLES.CHANNEL_ADMIN, ROLES.LITE_CHANNEL_ADMIN];
const ALLOW_AA_CA = [ROLES.ACCOUNT_ADMIN, ROLES.CHANNEL_ADMIN];
const ALLOW_AA = [ROLES.ACCOUNT_ADMIN];

export const permissions = {
  // Account Admin/Channel Admin pages
  [pages.DASHBOARD_PAGE]: ALLOW_AA_CA,
  [pages.VIDEO_DASHBOARD_PAGE]: ALLOW_AA_CA,
  //Allow All
  [pages.VIDEOS_LIBRARY_PAGE]: ALLOW_ALL,
  [pages.VIDEOS_PAGE]: ALLOW_ALL,
  [pages.CHANNELS_PAGE]: ALLOW_ALL,
  [pages.CHANNELS_VIDEOS_PAGE]: ALLOW_ALL,
  [pages.PROFILE_PAGE]: ALLOW_ALL,
  [pages.VIDEOS_REPORTS_PAGE]: ALLOW_ALL,
  [pages.SUBSCRIBERS_REPORTS_PAGE]: ALLOW_ALL,
  [pages.VIDEO_SERIES]: ALLOW_ALL,

  // Account Admin pages
  [pages.SETTINGS_PAGE]: ALLOW_AA,
  [pages.SUBSCRIBERS_PAGE]: ALLOW_AA,
  [pages.SUBSCRIBER_PAGE]: ALLOW_AA,
  [pages.SUBSCRIBER_DASHBOARD_PAGE]: ALLOW_AA,
  [pages.USERS_PAGE]: ALLOW_AA,
  [pages.MESSAGES_PAGE]: ALLOW_AA,

  // Admin only pages
  [pages.ADMIN_DASHBOARD_PAGE]: [],
  [pages.ADMIN_ACCOUNTS_PAGE]: [],
  [pages.ADMIN_ADD_ACCOUNT_PAGE]: [],
  [pages.ADMIN_MESSAGES_PAGE]: [],
  [pages.OTHER_CLINICS]: [],
};
