import { useEffect, useState } from "react";
import {
  Card,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Scrollbar from "components/Core/Scrollbar";
import { User as UserType } from "typings/user";
import useQuery from "hooks/useQuery";
import InviteUser from "./InviteUser";
import MoreOptions from "components/Core/MoreOptions";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "components/Core/Modal";
import { StyledTableCell } from "components/Core/Table/Table.styles";
import { useDialog } from "context/dialog";
import { formatDate } from "helpers/utils";

// import { getUsers } from "api/api";
import { getUsers as gqlGetUsers } from "api/graphql";
// import { updateUserRole } from "api/api";
import { updateUserRole as gqlUpdateUserRole } from "api/graphql";
import { getUsers } from "api/eventApi";

type Props = {
  isEvents?: boolean;
};

const Users = ({ isEvents }: Props) => {
  const limit = 20;
  const { showPageLoading } = useDialog();
  const { params, updateQueryParams } = useQuery();
  const { alertSuccess, alertError } = useDialog();

  const [page, setPage] = useState(+params.page || 0);
  // const [totalCount, setTotalCount] = useState(0);
  const [users, setUsers] = useState<UserType[]>([]);

  // const [roles, setRoles] = useState<string[]>([]);
  const [currentRole, setCurrentRole] = useState("");
  const [currentUserId, setCurrentUserId] = useState("");
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const TABLE_HEAD = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "role", label: "Role" },
    { id: "status", label: "Status" },
    { id: "createdAt", label: "Created Date" },
    { id: "", label: "" },
  ];

  const getData = async () => {
    try {
      showPageLoading(true);
      let users: any = [];
      if (isEvents) {
        users = await getUsers();
      } else {
        users = await gqlGetUsers();
      }

      setUsers(users ?? []);
    } catch (err) {
      console.log(err);
    }
    showPageLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event: any, newPage: number) => {
    updateQueryParams({ page: newPage, limit: limit });
    setPage(newPage);
  };

  const handlePermissionUpdate = async (e: any) => {
    setShowPermissionModal(false);
    showPageLoading(true);
    try {
      if (isEvents) {
        //
      } else {
        await gqlUpdateUserRole(e.target.id, e.target.value);
        await getData();
      }
      alertSuccess("Role updated successfully.");
    } catch (err) {
      alertError("Something went wrong! please try again after sometime.");
    }
    showPageLoading(false);
  };

  return (
    <Container maxWidth={"xl"}>
      <Modal open={showPermissionModal} onClose={() => setShowPermissionModal(false)}>
        <Typography variant="h4" gutterBottom>
          Update Permission
        </Typography>
        <FormControl>
          <RadioGroup defaultValue={currentRole} name="radio-buttons-group">
            {(isEvents
              ? ["events_admin"]
              : ["account_admin", "channel_admin", "lite_channel_admin"]
            ).map((r, index) => (
              <FormControlLabel
                key={index}
                value={r}
                control={<Radio id={currentUserId} />}
                label={
                  <Typography style={{ textTransform: "capitalize" }}>
                    {r.replaceAll("_", " ")}
                  </Typography>
                }
                onChange={handlePermissionUpdate}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Modal>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Users
        </Typography>
        <InviteUser isEvents={isEvents} />
      </Stack>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((headCell) => (
                    <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((row: UserType) => {
                  const { id, first_name, last_name, email, status, created_at, role } =
                    row;

                  return (
                    <TableRow hover key={id} tabIndex={-1}>
                      <TableCell>{first_name}</TableCell>
                      <TableCell>{last_name}</TableCell>
                      <TableCell>{email}</TableCell>
                      <TableCell>
                        {role && (
                          <Chip
                            label={role.replaceAll("_", " ")}
                            color={"primary"}
                            style={{ textTransform: "capitalize" }}
                          />
                        )}
                      </TableCell>

                      <TableCell align="left">
                        <Chip
                          label={status.toLowerCase()}
                          color={status === "ACTIVE" ? "success" : "error"}
                          style={{ textTransform: "capitalize" }}
                        />
                      </TableCell>
                      <TableCell>{formatDate(created_at)}</TableCell>
                      <TableCell align="right">
                        {!isEvents && (
                          <MoreOptions
                            options={[
                              {
                                label: "Change Role",
                                icon: <EditIcon width={24} height={24} />,
                                onClick: () => {
                                  setCurrentRole(role);
                                  setCurrentUserId(id);
                                  setShowPermissionModal(true);
                                },
                              },
                            ]}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={0}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
          />
        </Scrollbar>
      </Card>
    </Container>
  );
};

export default Users;
