import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  matchRoutes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getTheme } from "api/api";
import { useCurrentState } from "context/global";
import { getUserDataFromToken, isSessionValid } from "helpers/auth";

import pages from "enums/pages";
import { permissions } from "enums/permissions";
import { ROLES } from "enums/common";
import { isEventModule } from "helpers/utils";

const BasicLayout = () => {
  const { slug } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const routes = Object.values(pages).map((route) => ({ path: route }));
  const matches = matchRoutes(routes, location);

  const { globalState, setGlobalState } = useCurrentState();

  const isLoggedIn = !!isSessionValid();

  useEffect(() => {
    if (slug && slug !== "admin" && !isEventModule()) {
      getTheme(slug)
        .then((res: any) => {
          const state: any = {};
          if (res.primaryColor) state.primaryColor = res.primaryColor;
          if (res.secondaryColor) state.secondaryColor = res.secondaryColor;
          if (res.logo) state.logo = res.logo;
          setGlobalState(state);
        })
        .catch((err) => {
          console.log(err);
          // navigate(pages.ADMIN_LOGIN_PAGE);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    const token = getUserDataFromToken();
    const { soundbites = true, ideology = false } = token?.adminPanelType ?? {};
    if (soundbites) {
      if (isLoggedIn && (token?.isSuperAdmin || token?.role)) {
        if (pathname === pages.HOME_PAGE) {
          if (token?.role === ROLES.LITE_CHANNEL_ADMIN) {
            navigate(pages.CHANNELS_PAGE);
          } else {
            navigate(pages.DASHBOARD_PAGE);
          }
        } else {
          const route = matches && matches[0].route.path;
          if (
            route &&
            !token?.isSuperAdmin &&
            !permissions[route]?.includes(token?.role || "")
          ) {
            navigate(pages.PAGE_403);
          }
        }
      }
    } else if (ideology && !isEventModule()) {
      navigate(pages.EVENTS_DASHBOARD_PAGE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState, isLoggedIn, pathname]);

  if (!isLoggedIn) {
    if (slug) {
      const pathArray = pathname.replace(/^\/|\/$/g, "").split("/");
      if (pathArray.length === 1) {
        return <Navigate to={`/${slug}/login`} />;
      }
    } else {
      return (
        <Navigate
          to={pages.ADMIN_LOGIN_PAGE}
          state={{ from: location, search: location.search }}
        />
      );
    }
  }

  return <Outlet />;
};

export default BasicLayout;
