import React, { useEffect, useState } from "react";
import { Tab, TabContext, TabList, TabPanel } from "components/Core/Tab/Tab";
import useQuery from "hooks/useQuery";
import { Container, Typography } from "@mui/material";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import ViewListIcon from "@mui/icons-material/ViewList";
import AddEditModerator from "./addEditModerator";
import ModeratorDetails from "./moderatorDetail";

export default function Moderator() {
  const { params, hash, updateUri } = useQuery();
  const [tabValue, setTabValue] = useState("1");
  const isNew = params.new === "true";

  useEffect(() => {
    switch (hash) {
      case "#edit":
        setTabValue("2");
        break;
      default:
        setTabValue("1");
        break;
    }
  }, [hash]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let hashVal = "";
    switch (newValue) {
      case "1":
        hashVal = "#overview";
        break;
      case "2":
        hashVal = "#edit";
        break;
    }
    updateUri(hashVal);
    setTabValue(newValue);
  };
  return (
    <Container maxWidth={"xl"}>
      <Typography variant="h4" mb={5}>
        Moderators
      </Typography>

      {isNew && (
        <>
          <h1>Create New Moderator</h1>
          <AddEditModerator />
        </>
      )}

      {!isNew && (
        <TabContext value={tabValue}>
          <TabList onChange={handleChange}>
            <Tab
              iconPosition="start"
              label="Moderator Details"
              icon={<VideoSettingsIcon />}
              value="1"
            />
            <Tab iconPosition="start" label="Edit" icon={<ViewListIcon />} value="2" />
          </TabList>
          <TabPanel value="1">
            <ModeratorDetails />
          </TabPanel>
          <TabPanel value="2">
            <AddEditModerator />
          </TabPanel>
        </TabContext>
      )}
    </Container>
  );
}
