import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { getModerators } from "api/eventApi";

export default function ModeratorDetails() {
  const { id } = useParams();
  const [moderatorData, setModeratorData] = useState<any>({});

  useEffect(() => {
    const fetchModeratorData = async () => {
      if (id) {
        try {
          const res: any = await getModerators(id);
          setModeratorData(res.rows[0]);
        } catch (error) {
          console.error("Error fetching moderator data:", error);
        }
      }
    };
    fetchModeratorData();
  }, [id]);

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Moderator Details
        </Typography>
        <Grid container spacing={2}>
          {/* First Name and Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              value={moderatorData.first_name || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              value={moderatorData.last_name || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Full Name and Credentials */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Full Name"
              value={moderatorData.full_name || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Credentials"
              value={moderatorData.credentials || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Institution and Institution City */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Institution"
              value={moderatorData.insitution || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Institution City"
              value={moderatorData.insitution_city || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Specialty and Region */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Specialty"
              value={moderatorData.specialty || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Region"
              value={moderatorData.region || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Boolean Fields */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={moderatorData.IDEO_speaker || false} disabled />
              }
              label="IDEO Speaker"
            />
            <FormControlLabel
              control={<Checkbox checked={moderatorData.soundbites || false} disabled />}
              label="Soundbites"
            />
            <FormControlLabel
              control={<Checkbox checked={moderatorData.IDEO_SWOT || false} disabled />}
              label="IDEO SWOT"
            />
            <FormControlLabel
              control={
                <Checkbox checked={moderatorData.IDEO_conference || false} disabled />
              }
              label="IDEO Conference"
            />
            <FormControlLabel
              control={
                <Checkbox checked={moderatorData.content_creation || false} disabled />
              }
              label="Content Creation"
            />
          </Grid>

          {/* Willing to Travel */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Willing to Travel"
              value={moderatorData.willing_to_travel || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* IDEO Contact */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="IDEO Contact"
              value={moderatorData.IDEO_contact || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Address and Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Address"
              value={moderatorData.address || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              value={moderatorData.email || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Phone and DOB */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone"
              value={moderatorData.phone || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date of Birth"
              value={moderatorData.dob || ""}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Preferences */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Preferences"
              value={
                Object.entries(moderatorData.preferences || {})
                  .map(([key, value]) => `${key}: ${value}`)
                  .join("; ") || ""
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>

          {/* Photo */}
          <Grid item xs={12}>
            {moderatorData.photo && (
              <Box mt={2}>
                <Typography variant="subtitle1">Headshot</Typography>
                <img
                  src={moderatorData.photo}
                  alt="Headshot"
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "8px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
