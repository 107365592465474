import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { User } from "typings/user";
import { useCurrentState } from "./global";
import { getUserDataFromToken } from "helpers/auth";
import { getSession } from "api/api";
import { CircularProgress, Stack } from "@mui/material";

export type UserProps = {
  user?: User;
};

type UserContextProps = {
  user?: User;
  refreshUser: () => Promise<User>;
};

const UserContext = createContext<UserContextProps>({
  refreshUser: () => Promise.reject("No current user."),
});

export const UserProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<User | undefined>();
  const { globalState, setGlobalState } = useCurrentState();
  const [loading, setLoading] = useState(false);

  const refreshUser = useCallback(async () => {
    const token = getUserDataFromToken();

    let session: any = {};
    if (token.adminPanelType.soundbites) {
      session = await getSession();
    }

    const user = { ...token, ...session };
    setUser(user);
    //
    const state: any = { ...globalState };

    if (token.adminPanelType.soundbites) {
      const { name, logo, primaryColor, secondaryColor } = session?.account ?? {};
      if (logo) state.logo = logo;
      if (primaryColor) state.primaryColor = primaryColor;
      if (secondaryColor) state.secondaryColor = secondaryColor;
      if (name) state.accountName = name;
    }
    //
    state.role = user.role;
    state.isSuperAdmin = user.isSuperAdmin;
    state.adminPanelType = user.adminPanelType;
    setGlobalState(state);
    //
    return user;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    refreshUser().finally(() => {
      setLoading(false);
    });
  }, [refreshUser]);

  return (
    <UserContext.Provider value={{ user, refreshUser }}>
      {loading ? (
        <Stack width={1} justifyContent={"center"} alignItems={"center"}>
          <CircularProgress />
        </Stack>
      ) : (
        <>{children}</>
      )}
    </UserContext.Provider>
  );
};

export const useCurrentUser = () => {
  return useContext(UserContext);
};
