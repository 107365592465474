import React, { useEffect, useState } from "react";
import AddEditEvent from "./addEditEvent";
import Registrations from "./registrations";
import { Tab, TabContext, TabList, TabPanel } from "components/Core/Tab/Tab";
import useQuery from "hooks/useQuery";
import { Container, Typography } from "@mui/material";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import ViewListIcon from "@mui/icons-material/ViewList";
import EventOverview from "./overview";
import Tickets from "./tickets";

export default function Event() {
  const { params, hash, updateUri } = useQuery();
  const [tabValue, setTabValue] = useState("1");
  const isNew = params.new === "true";

  useEffect(() => {
    switch (hash) {
      case "#registrations":
        setTabValue("2");
        break;
      case "#edit":
        setTabValue("3");
        break;
      case "#tickets":
        setTabValue("4");
        break;
      default:
        setTabValue("1");
        break;
    }
  }, [hash]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    let hashVal = "";
    switch (newValue) {
      case "1":
        hashVal = "#overview";
        break;
      case "2":
        hashVal = "#registrations";
        break;
      case "3":
        hashVal = "#edit";
        break;
      case "4":
        hashVal = "#tickets";
        break;
    }
    updateUri(hashVal);
    setTabValue(newValue);
  };
  return (
    <Container maxWidth={"xl"}>
      <Typography variant="h4" mb={5}>
        Events
      </Typography>

      {isNew && (
        <>
          <h1>Create New Event</h1>
          <AddEditEvent />
        </>
      )}

      {!isNew && (
        <TabContext value={tabValue}>
          <TabList onChange={handleChange}>
            <Tab
              iconPosition="start"
              label="Event Overview"
              icon={<VideoSettingsIcon />}
              value="1"
            />
            <Tab
              iconPosition="start"
              label="Registrations"
              icon={<ViewListIcon />}
              value="2"
            />

            <Tab iconPosition="start" label="Edit" icon={<ViewListIcon />} value="3" />
            <Tab iconPosition="start" label="Tickets" icon={<ViewListIcon />} value="4" />
          </TabList>
          <TabPanel value="1">
            <EventOverview />
          </TabPanel>
          <TabPanel value="2">
            <Registrations />
          </TabPanel>
          <TabPanel value="3">
            <AddEditEvent />
          </TabPanel>
          <TabPanel value="4">
            <Tickets />
          </TabPanel>
        </TabContext>
      )}
    </Container>
  );
}
