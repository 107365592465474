import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import pages from "enums/pages";
import { v4 as uuid } from "uuid";
import Scrollbar from "components/Core/Scrollbar";
import Table from "components/Core/Table/Table";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import RowSelection from "components/Core/Table/RowSelection";
import { getEvents } from "api/eventApi";
import MoreOptions from "components/Core/MoreOptions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ViewListIcon from "@mui/icons-material/ViewList";
import { localStorageGetItem } from "helpers/utils";
import useQuery from "hooks/useQuery";
import Filters from "./Filters";

export default function Dashboard() {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<any>();
  const [events, setEvents] = React.useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { params } = useQuery();

  const limit = 20;
  const page = +params.page || 0;
  const search = params.search || "";
  const eventFilter = params.event || "all";

  const getEventsData = async (
    search: string,
    eventFilter: any,
    sorting: any,
    page: number,
    limit: number,
  ) => {
    const sort = sorting.length
      ? `${sorting[0].id}:${sorting[0].desc ? "desc" : "asc"}`
      : "";
    const eventType = "conference";
    const filter: any = { search, eventFilter, eventType, sort };
    const data: any = await getEvents("", filter, limit, page);
    setEvents(data.rows);
    setTotalCount(data.count);
  };

  useEffect(() => {
    getEventsData(search, eventFilter, sorting, page, limit);
  }, [search, eventFilter, sorting, page, limit]);

  const [columnVisibility, setColumnVisibility] = useState<any>({});

  const columns: any = [
    {
      id: "select",
      header: ({ table }: any) => (
        <RowSelection
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <RowSelection
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor("Name", {
      id: "name",
      header: "Name",
      cell: ({ row }: any) => {
        const eventLink = `${pages.CONFERENCE_PAGE.replace(":id", row.original.id)}`;
        return (
          <span
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => navigate(eventLink)} // Navigate to the event page
          >
            {row.original.name}
          </span>
        );
      },
    }),
    columnHelper.accessor("Type", {
      id: "type",
      header: "Type",
      cell: ({ row }: any) => {
        return row.original.type;
      },
    }),
    columnHelper.accessor("Location", {
      id: "location",
      header: "Location",
      cell: ({ row }: any) => {
        return row.original.location;
      },
    }),
    columnHelper.accessor("Meeting Type", {
      id: "meetingType",
      header: "Meeting Type",
      cell: ({ row }: any) => {
        return row.original.meeting_type;
      },
    }),
    columnHelper.accessor("Time Zone", {
      id: "timeZone",
      header: "Time Zone",
      cell: ({ row }: any) => {
        return row.original.timezone;
      },
    }),
    columnHelper.accessor("Start Time", {
      id: "start_time", //this id is compatible with the backend sorting, db column name
      header: ({ column }: any) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Start Time{" "}
          {column.getIsSorted() === "asc"
            ? "↑"
            : column.getIsSorted() === "desc"
            ? "↓"
            : ""}
        </span>
      ),
      cell: ({ row }: any) => {
        return row.original.start_time;
      },
    }),
    columnHelper.accessor("End Time", {
      id: "end_time", //this id is compatible with the backend sorting, db column name
      header: ({ column }: any) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          End Time{" "}
          {column.getIsSorted() === "asc"
            ? "↑"
            : column.getIsSorted() === "desc"
            ? "↓"
            : ""}
        </span>
      ),
      cell: ({ row }: any) => {
        return row.original.end_time;
      },
    }),
    columnHelper.accessor("Description", {
      id: "description",
      header: "Description",
      cell: ({ row }: any) => {
        return row.original.description;
      },
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: "",
      cell: ({ row }: any) => {
        const eventLink = `${pages.EVENT_PAGE.replace(":id", row.original.id)}`;
        return (
          <MoreOptions
            options={[
              {
                label: "Edit",
                icon: <EditIcon width={24} height={24} />,
                link: eventLink + "#edit",
              },
              {
                label: "Registrations",
                icon: <ViewListIcon width={24} height={24} />,
                link: eventLink + "#registrations",
              },
              {
                label: "Delete",
                icon: <DeleteIcon width={24} height={24} />,
                // onClick: () => confirmVideoDeletion(row.original.id, row.original.title),
              },
            ]}
          />
        );
      },
    }),
  ];

  const excludeColFromEdit: any = {};

  const visibleColums = columns
    .filter((col: any) => col.header && typeof col.header === "string")
    .filter((col: any) =>
      excludeColFromEdit[col.id] !== undefined ? excludeColFromEdit[col.id] : true,
    )
    .map((col: any) => ({ value: col.id, label: col.header }));

  // hide/show columns
  const updateColumnVisibility = useCallback(() => {
    const storedColumns = JSON.parse(localStorageGetItem("eventsTableColumns") || "[]");

    if (storedColumns.length) {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(storedColumns.map((col: string) => [col, true])),
        ...Object.fromEntries(
          visibleColums
            .filter((col: any) => !storedColumns.includes(col.value))
            .map((col: any) => [col.value, false]),
        ),
      }));
    } else {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(visibleColums.map((col: any) => [col.value, true])),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateColumnVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const table: any = useReactTable({
    data: events,
    columns,
    state: { sorting, columnVisibility },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <Container maxWidth={"xl"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Conference
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate(pages.CONFERENCE_PAGE.replace(":id", uuid()) + "?new=true");
          }}
          startIcon={<AddIcon />}
          size="large"
        >
          New Conference
        </Button>
      </Stack>

      <Filters columns={visibleColums} updateColumnVisibility={updateColumnVisibility} />
      <Card>
        <Scrollbar>
          <Table table={table} totalCount={totalCount} rowsPerPage={limit} />
        </Scrollbar>
      </Card>
    </Container>
  );
}
