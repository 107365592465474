export const UPLOAD_DIR = {
  VIDEOS: "videos",
  LOGO: "logo",
  THUMBNAIL: "thumbnail",
  MODERATORS: "moderators",
};

export const IH_SLUG = "ideology-health";

export const BEGINNING_DATE = "2022-06-01";

export const MESSAGES_TYPES = {
  WELCOME: "WELCOME",
  WELCOME_FOR_IMPORTED: "WELCOME_FOR_IMPORTED",
  UPDATE_AOI_REQUEST: "UPDATE_AOI_REQUEST",
  AOI_UPDATE_RESPONSE: "AOI_UPDATE_RESPONSE",
  WATCH_FULL_VIDEO: "WATCH_FULL_VIDEO",
};

export const SHOW_ON_STAGING_ONLY = process.env.REACT_APP_STAGE === "staging";

export const ROLES = {
  ACCOUNT_ADMIN: "account_admin",
  CHANNEL_ADMIN: "channel_admin",
  LITE_CHANNEL_ADMIN: "lite_channel_admin",
};

export const EVENTS_ROLES = {
  EVENTS_ADMIN: "events_admin",
};

export const SUBSCRIBER_ROLES = {
  DOCTOR: "doctor",
};

export const SUBSCRIBER_ROLES_OPTION = [{ label: "Doctor", value: "doctor" }];
