import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import { signIn } from "helpers/auth";
import { Link as RouterLink } from "react-router-dom";

import AlertToast from "components/Core/Toast";
import Error from "components/Core/Error";
import pages from "enums/pages";
import emailEnum from "enums/email";
import validationSchemas from "helpers/validation_schemas";
import { LoadingButton } from "@mui/lab";
import { Checkbox, Stack } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useParams } from "react-router-dom";
import { isEventModule, updateSlugInUrl } from "helpers/utils";
import { getUserDataFromToken } from "helpers/auth";
import { ROLES } from "enums/common";
import Logo, { CombinedLogo } from "components/Core/Logo/Logo";

const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: pages.DASHBOARD_PAGE } };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);

  const validateFormData = () => {
    setErrors([]);
    return validationSchemas.signin_schema
      .validate(
        {
          email,
          password,
        },
        { abortEarly: false },
      )
      .then(() => true)
      .catch((err) => {
        setErrors(err.inner.map((e: Error) => e.message));
        return false;
      });
  };

  const { slug } = useParams();

  const login = async (e: FormEvent) => {
    e.preventDefault();

    const isValid = await validateFormData();
    if (!isValid) return;

    setIsLoading(true);
    setShowError(false);
    console.log("click sign in");
    signIn(email, password, slug || "")
      .then(() => {
        console.log("clicked");
        const user = getUserDataFromToken();
        // const isEvents = slug === "events";
        const { soundbites = true, ideology = false } = user?.adminPanelType ?? {};
        if (soundbites) {
          if (from.pathname !== pages.DASHBOARD_PAGE) {
            navigate(from.pathname + from.search);
          } else if (user.isSuperAdmin || user?.role === ROLES.CHANNEL_ADMIN) {
            window.location.href = pages.DASHBOARD_PAGE;
          } else {
            window.location.href = pages.CHANNELS_PAGE;
          }
        } else if (ideology) {
          window.location.href = pages.EVENTS_DASHBOARD_PAGE;
        }
      })
      .catch((error) => {
        setShowError(true);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <AlertToast
        open={showError}
        message={error}
        alertType="error"
        onClose={() => setShowError(false)}
      />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isEventModule() ? <Logo width={250} /> : <CombinedLogo />}

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <FormControlLabel control={<Checkbox checked={true} />} label="Remember me" />

            <Link
              component={RouterLink}
              variant="subtitle2"
              to={`${updateSlugInUrl(pages.FORGOT_PASSWORD_PAGE, slug || "admin")}`}
              underline="hover"
            >
              Forgot password?
            </Link>
          </Stack>

          {errors && errors.length > 0 && (
            <Error title="Validation Error" errors={errors} />
          )}

          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={isLoading}
            onClick={login}
            type="submit"
          >
            Login
          </LoadingButton>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            {/* <Box>
              Need an account?
              <Link
                variant="subtitle2"
                underline="hover"
                href={`updateSlugInUrl(pages.SIGNUP_PAGE, slug)}`}
              >
                Signup
              </Link>
            </Box> */}
            <Box>
              Need Help?
              <Link
                variant="subtitle2"
                underline="hover"
                href={`mailto:${emailEnum.CONTACT_US_EMAIL}`}
              >
                Contact Us
              </Link>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default Signin;
