import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useCurrentState } from "context/global";
import React, { useMemo, useState } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { useNavigate } from "react-router-dom";
import { isEventModule } from "helpers/utils";

export const soundbites = "sound bites";
export const ideology = "events";

const SwitchModules = () => {
  const navigate = useNavigate();
  const { globalState } = useCurrentState();

  const isEvents = isEventModule();
  const currentModule = useMemo(() => (isEvents ? ideology : soundbites), [isEvents]);

  const showSwitchModules = useMemo(
    () =>
      globalState?.adminPanelType?.soundbites && globalState?.adminPanelType?.ideology,
    [globalState],
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const switchModules = (module: string) => {
    if (module === soundbites) {
      navigate("/dashboard");
    } else if (module === ideology) {
      navigate("/events/dashboard");
    }
    setAnchorEl(null);
  };

  return showSwitchModules ? (
    <>
      <Tooltip title="Switch Modules">
        <IconButton id="switch-modules" onClick={handleClick}>
          <WidgetsOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="switch-modules"
        MenuListProps={{ "aria-labelledby": "long-button" }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {[soundbites, ideology].map((option) => (
          <MenuItem
            key={option}
            selected={option === currentModule}
            onClick={() => switchModules(option)}
            sx={{ py: 1, px: 4, textTransform: "capitalize" }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : (
    <></>
  );
};

export default SwitchModules;
