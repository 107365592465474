import { FormEvent, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { signUp } from "helpers/auth";
import { LoadingButton } from "@mui/lab";
import Error from "components/Core/Error";
import pages from "enums/pages";
import emailEnum from "enums/email";
import validationSchemas from "helpers/validation_schemas";
import { Stack } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useQuery from "hooks/useQuery";
import { useParams } from "react-router-dom";
import { isEventModule, updateSlugInUrl } from "helpers/utils";
import Logo, { CombinedLogo } from "components/Core/Logo/Logo";
import { useDialog } from "context/dialog";

const Signup = () => {
  const { alertSuccess, alertError } = useDialog();
  const {
    params: { email: invitedEmail, invite: inviteCipher },
  } = useQuery();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(invitedEmail);
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);

  const { slug } = useParams();

  const validateFormData = () => {
    setErrors([]);
    return validationSchemas.signup_schema
      .validate(
        {
          email,
          password,
          firstName,
          lastName,
          mobile,
        },
        { abortEarly: false },
      )
      .then(() => true)
      .catch((err) => {
        setErrors(err.inner.map((e: Error) => e.message));
        return false;
      });
  };

  const register = async (e: FormEvent) => {
    e.preventDefault();

    const isValid = await validateFormData();
    if (!isValid) return;

    setIsLoading(true);

    signUp(
      email,
      password,
      firstName,
      lastName,
      mobile,
      slug || "admin",
      inviteCipher,
      isEventModule()
        ? { soundbites: false, ideology: true }
        : { soundbites: true, ideology: false },
    )
      .then(() => {
        alertSuccess("Singup successful, confirmation email sent to your email address");
        setTimeout(() => {
          if (slug) window.location.href = updateSlugInUrl(pages.LOGIN_PAGE, slug);
        }, 3000);
      })
      .catch((error) => {
        if (error.message.includes("EmailSentSuccessfully")) {
          alertSuccess(
            "Singup successful, confirmation email sent to your email address",
          );
          setTimeout(() => {
            if (slug) window.location.href = updateSlugInUrl(pages.LOGIN_PAGE, slug);
          }, 3000);
        } else {
          alertError(error.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isEventModule() ? <Logo width={250} /> : <CombinedLogo />}

        <Box component="form" noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            disabled={invitedEmail !== ""}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="mobile"
            label="Phone Number"
            name="mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {errors && errors.length > 0 && (
            <Error title="Validation Error" errors={errors} />
          )}

          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={isLoading}
            onClick={register}
            sx={{ my: 2 }}
          >
            Signup
          </LoadingButton>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 1 }}
          >
            <Box>
              Already a user?
              <Link
                variant="subtitle2"
                underline="hover"
                href={`${updateSlugInUrl(pages.LOGIN_PAGE, slug)}`}
              >
                Login
              </Link>
            </Box>
            <Box>
              Need Help?
              <Link
                variant="subtitle2"
                underline="hover"
                href={`mailto:${emailEnum.CONTACT_US_EMAIL}`}
              >
                Contact Us
              </Link>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
};

export default Signup;
