import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  InputLabel,
  Link,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useQuery from "hooks/useQuery";

import InputField from "components/Core/Inputs/Input";
import VALIDATION from "helpers/validation_schemas";

import { useDialog } from "context/dialog";
import {
  createVideoSeries,
  getMuxVideoLink,
  getVideoSeries,
  getVideoUploadUrl,
} from "api/api";
import Videos from "./Videos";
import pages from "enums/pages";
import { v4 as uuid } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import Modal from "components/Core/Modal";
import FileUpload from "components/Core/Inputs/FileUpload";
import * as UpChunk from "@mux/upchunk";
import { MUX_BASE_URL } from "components/Videos/AddEditVideo";

const EditVideoSeries = () => {
  const { id } = useParams();
  const { alertSuccess, alertError, showPageLoading } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [videoFile, setVideoFile] = useState<any>();
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showLinkForLong, setShowLinkForLong] = useState(true);

  const { params } = useQuery();
  const isNew = params.new === "true";

  const [videoSeries, setVideoSeries] = useState<any>();

  const getData = useCallback(async () => {
    showPageLoading(true);
    try {
      if (!isNew && id) {
        const res: any = await getVideoSeries(id);
        setVideoSeries(res.videoSeries);
      }

      //
    } catch (err) {
      console.log(err);
    }
    showPageLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isNew]);

  useEffect(() => {
    getData();
  }, [getData]);

  const formik = useFormik({
    initialValues: {
      name: videoSeries?.name || "",
      description: videoSeries?.description || "",
      videoLink: videoSeries?.video_link || "",
      longVideoTitle: videoSeries?.long_video_title || "",
      longVideoMessage: videoSeries?.long_video_message || "",
    },
    enableReinitialize: true,
    validationSchema: VALIDATION.video_series_schema,
    onSubmit: async (values) => {
      if (!id) return;

      setIsLoading(true);
      try {
        await createVideoSeries(id, values);

        // remove query params
        window.history.pushState({}, document.title, window.location.pathname);

        alertSuccess("Video series updated successfully");

        navigate(pages.VIDEO_SERIES);
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }

      setIsLoading(false);
    },
  });

  const handleUpload = async () => {
    if (!id) return;
    try {
      await VALIDATION.video_file_upload.validate({
        file: videoFile,
      });
    } catch (err: any) {
      alertError(err.message);
      return;
    }

    try {
      setIsVideoLoading(true);
      const data: any = await getVideoUploadUrl(id, "long_video");

      const upload = UpChunk.createUpload({
        endpoint: data.url, // Authenticated url
        file: videoFile, // File object with your video file’s properties
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      // Subscribe to events
      upload.on("error", (error) => {
        alertError(error.detail);
      });

      upload.on("progress", (progress) => {
        setProgress(progress.detail);
      });

      upload.on("success", async () => {
        const videoDetail: any = await getMuxVideoLink(data.id);
        let fileName;
        if (videoDetail.max_stored_resolution === "HD") {
          fileName = "high.mp4";
        } else {
          fileName = "low.mp4";
        }

        const link = `${MUX_BASE_URL}/${videoDetail.playback_ids[0].id}/${fileName}`;

        formik.setFieldValue("videoLink", link);
        setShowLinkForLong(true);

        setIsVideoLoading(false);
        setShowUploadModal(false);
        setProgress(0);
      });
    } catch (error: any) {
      console.log(error);
      alertError(error.message);
    }
  };

  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Modal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        width={"600px"}
      >
        <FileUpload
          id="video"
          accept="video"
          hoverLabel="Click or drag file here and click upload button"
          dropLabel="Drop file here and click upload button"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files !== null && event.target?.files?.length > 0) {
              setVideoFile(event.target.files[0]);
            }
          }}
          onDrop={(event: React.DragEvent<HTMLElement>) => {
            setVideoFile(event.dataTransfer.files[0]);
          }}
          imageButton
        />
        <Typography align="center" sx={{ opacity: "0.5" }}>
          {"Allowed *.mp4, *.m4v, *.avi, *.mov"}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <LoadingButton
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            loading={isVideoLoading}
            onClick={handleUpload}
            loadingIndicator={<CircularProgress variant="determinate" value={progress} />}
          >
            Upload
          </LoadingButton>
        </Box>
      </Modal>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Create Video Series
        </Typography>
        {!isNew && videoSeries && (
          <Stack direction={"row"} gap={1}>
            <Button
              variant="contained"
              LinkComponent={Link}
              href={
                pages.VIDEOS_PAGE.replace(":id", uuid()) +
                "?new=true&seriesid=" +
                videoSeries?.id
              }
              startIcon={<AddIcon />}
            >
              New Video
            </Button>

            <Button
              variant="contained"
              startIcon={<AddIcon />}
              LinkComponent={Link}
              href={pages.VIDEOS_LIBRARY_PAGE + "?seriesid=" + videoSeries?.id}
            >
              Add Videos To Series
            </Button>
          </Stack>
        )}
      </Stack>
      <Card sx={{ p: 5 }}>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <InputField
              label="Name"
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />

            <InputField
              label="Description"
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              rows={4}
              multiline
            />

            <InputField
              label="Long Video Title"
              id="longVideoTitle"
              name="longVideoTitle"
              value={formik.values.longVideoTitle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.longVideoTitle && Boolean(formik.errors.longVideoTitle)
              }
              helperText={formik.touched.longVideoTitle && formik.errors.longVideoTitle}
              fullWidth
            />

            <>
              <Stack direction="row" spacing={10} alignItems="center" mb={1}>
                <InputLabel
                  error={formik.touched.videoLink && Boolean(formik.errors.videoLink)}
                >
                  Long Video
                </InputLabel>
                <Stack direction="row" alignItems="center">
                  <Typography>Video</Typography>
                  <Switch
                    checked={showLinkForLong}
                    onChange={() => setShowLinkForLong(!showLinkForLong)}
                  />
                  <Typography>Link</Typography>
                </Stack>
              </Stack>

              {showLinkForLong ? (
                <InputField
                  id="videoLink"
                  name="videoLink"
                  value={formik.values.videoLink}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.videoLink && Boolean(formik.errors.videoLink)}
                  helperText={formik.touched.videoLink && formik.errors.videoLink}
                  fullWidth
                />
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    setShowUploadModal(true);
                  }}
                  sx={{ width: 200 }}
                >
                  Upload Video
                </Button>
              )}
            </>
            <InputField
              label="Message For Long Video"
              id="longVideoMessage"
              name="longVideoMessage"
              placeholder="Click here to view full length video: {{LINK}}"
              value={formik.values.longVideoMessage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.longVideoMessage && Boolean(formik.errors.longVideoMessage)
              }
              helperText={
                formik.touched.longVideoMessage ? formik.errors.longVideoMessage : ""
              }
              fullWidth
            />

            <Box textAlign={"right"}>
              <Button
                color="error"
                variant="contained"
                size="large"
                sx={{ mr: 2 }}
                onClick={() => {
                  window.history.back();
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                size="large"
                variant="contained"
                loading={isLoading}
              >
                Save Video Series
              </LoadingButton>
            </Box>
          </Stack>
        </Box>
      </Card>
      {!isNew && videoSeries && (
        <Card sx={{ mt: 2 }}>
          <Videos videos={videoSeries?.Videos ?? []} />
        </Card>
      )}
    </Container>
  );
};

export default EditVideoSeries;
