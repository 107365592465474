import React from "react";
import { useEffect, useState } from "react";
import { Box, Container, MenuItem, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import DateTimePicker from "components/Core/DateTimePicker";
import moment from "moment";
import { getEvents, upsertEvent } from "api/eventApi";
import { LoadingButton } from "@mui/lab";
import { useDialog } from "context/dialog";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import pages from "enums/pages"; // Import pages enum

const eventTypes = [
  { value: "conference", label: "Conference" },
  { value: "IDEO_Talk", label: "IDEO_Talk" },
];

const timeZones = [
  { value: "UTC", label: "UTC" },
  { value: "PST", label: "PST" },
  { value: "EST", label: "EST" },
];

const meetingTypes = [
  { value: "inperson", label: "In-person" },
  { value: "virtual", label: "Virtual" },
  { value: "hybrid", label: "Hybrid" },
];

const validationSchema = Yup.object({
  eventName: Yup.string().required("Event name is required"),
  eventType: Yup.string().required("Event type is required"),
  timeZone: Yup.string().required("Time zone is required"),
  eventStarts: Yup.date().required("Event start time is required"),
  eventEnds: Yup.date().required("Event end time is required"),
  eventDescription: Yup.string().required("Event description is required"),
  location: Yup.string().required("Location is required"),
  meetingType: Yup.string().required("Meeting type is required"),
});

export default function AddEditEvent() {
  const { id } = useParams();

  const navigate = useNavigate(); // Initialize navigate
  const [isLoading, setIsLoading] = useState(false);
  const { alertSuccess, alertError } = useDialog();
  const [eventData, setEventData] = useState<any>({});

  useEffect(() => {
    const fetchEventData = async () => {
      if (id) {
        try {
          const res: any = await getEvents(id);
          console.log(res);
          setEventData(res.rows[0]);
        } catch (error) {
          console.error("Error fetching event data:", error);
        }
      }
    };
    fetchEventData();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      eventName: eventData?.name || "",
      eventType: eventData?.type || "",
      timeZone: eventData?.timezone || "",
      eventStarts: eventData?.start_time || null,
      eventEnds: eventData?.end_time || null,
      eventDescription: eventData?.description || "",
      location: eventData?.location || "",
      meetingType: eventData?.meeting_type || "",
    },
    enableReinitialize: true, // Add this to enable reinitialization
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        upsertEvent(values, id);
        alertSuccess("Event updated successfully");
        navigate(pages.EVENTS_LIST_PAGE); // Redirect to pages.events
      } catch (err) {
        if (err instanceof Error) {
          console.log(err);
          alertError(err.message);
        }
      }
      setIsLoading(false);
    },
  });
  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="eventName"
          name="eventName"
          label="Event Name"
          value={formik.values.eventName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.eventName && Boolean(formik.errors.eventName)}
          helperText={formik.touched.eventName && formik.errors.eventName}
          margin="normal"
        />
        <TextField
          fullWidth
          id="eventType"
          name="eventType"
          label="Event Type"
          select
          value={formik.values.eventType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.eventType && Boolean(formik.errors.eventType)}
          helperText={formik.touched.eventType && formik.errors.eventType}
          margin="normal"
        >
          {eventTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          fullWidth
          id="timeZone"
          name="timeZone"
          label="Time Zone"
          select
          value={formik.values.timeZone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.timeZone && Boolean(formik.errors.timeZone)}
          helperText={formik.touched.timeZone && formik.errors.timeZone}
          margin="normal"
        >
          {timeZones.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id="location"
          name="location"
          label="Location"
          value={formik.values.location}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location}
          margin="normal"
        />
        <TextField
          fullWidth
          id="meetingType"
          name="meetingType"
          label="Meeting Type"
          select
          value={formik.values.meetingType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.meetingType && Boolean(formik.errors.meetingType)}
          helperText={formik.touched.meetingType && formik.errors.meetingType}
          margin="normal"
        >
          {meetingTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <DateTimePicker
          label="Event Starts"
          orientation="landscape"
          ampm={false}
          value={formik.values.eventStarts && moment(formik.values.eventStarts)}
          onChange={(date) => {
            formik.setFieldValue("eventStarts", date);
          }}
          error={formik.touched.eventStarts && Boolean(formik.errors.eventStarts)}
          helperText={formik.touched.eventStarts && formik.errors.eventStarts}
        />
        <DateTimePicker
          label="Event Ends"
          orientation="landscape"
          ampm={false}
          value={formik.values.eventEnds && moment(formik.values.eventEnds)}
          onChange={(date) => {
            formik.setFieldValue("eventEnds", date);
          }}
          error={formik.touched.eventEnds && Boolean(formik.errors.eventEnds)}
          helperText={formik.touched.eventEnds && formik.errors.eventEnds}
        />

        <TextField
          fullWidth
          id="eventDescription"
          name="eventDescription"
          label="Event Description"
          multiline
          rows={4}
          value={formik.values.eventDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.eventDescription && Boolean(formik.errors.eventDescription)
          }
          helperText={formik.touched.eventDescription && formik.errors.eventDescription}
          margin="normal"
        />
        <Box mt={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            loading={isLoading}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}
