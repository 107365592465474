import { FormEvent, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { forgotPassword } from "helpers/auth";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "components/Core/Button";
import { resetPassword } from "api/api";
import ueQuery from "hooks/useQuery";
import Error from "components/Core/Error";
import validationSchemas from "helpers/validation_schemas";
import Logo, { CombinedLogo } from "components/Core/Logo/Logo";
import { useDialog } from "context/dialog";
import { isEventModule } from "helpers/utils";

const ForgotPassword = () => {
  const {
    params: { code: token, username, type },
  } = ueQuery();

  const { alertSuccess, alertError } = useDialog();

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const validateFormData = () => {
    setErrors([]);
    return validationSchemas.reset_password_schema
      .validate(
        {
          newPassword,
          confirmPassword,
        },
        { abortEarly: false },
      )
      .then(() => {
        if (newPassword !== confirmPassword) {
          setErrors(["Confirm Password field should match with new password field."]);
          return false;
        }
        return true;
      })
      .catch((err) => {
        setErrors(err.inner.map((e: Error) => e.message));
        return false;
      });
  };

  const forgotPasswordRequest = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    forgotPassword(
      email,
      () => {
        setIsLoading(false);
        alertSuccess("Forgot password code sent on your registered email");
      },
      (err: Error) => {
        if (err.message.includes("EmailSentSuccessfully")) {
          setIsLoading(false);
          alertSuccess("Forgot password code sent on your registered email");
        } else {
          setIsLoading(false);

          if (err instanceof Error) {
            alertError(err.message);
          } else {
            alertError("Something went wrong");
          }
        }
      },
    );
  };

  const updatePassword = async (e: FormEvent) => {
    if (!username || !token || !type) return;

    const isValid = await validateFormData();
    if (!isValid) return;

    e.preventDefault();
    setIsLoading(true);

    resetPassword(username, token, type, newPassword)
      .then(() => {
        setIsLoading(false);
        alertSuccess("Password update successfully");

        setTimeout(() => {
          const from =
            (location.state as { from: { pathname: string } })?.from?.pathname || "/";
          navigate(from, { replace: true });
        }, 2000);
      })
      .catch((err: Error) => {
        setIsLoading(false);
        if (err instanceof Error) {
          alertError(err.message);
        } else {
          alertError("Something went wrong");
        }
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isEventModule() ? <Logo width={250} /> : <CombinedLogo />}

        {!token ? (
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <Button
              fullWidth
              variant="contained"
              onClick={forgotPasswordRequest}
              isLoading={isLoading}
            >
              Forgot Password
            </Button>
          </Box>
        ) : (
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              type="password"
              margin="normal"
              required
              fullWidth
              label="New Password"
              name="newPassword"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
            />
            <TextField
              type="password"
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
            />

            {errors && errors.length > 0 && (
              <Error title="Validation Error" errors={errors} />
            )}

            <Button
              fullWidth
              variant="contained"
              onClick={updatePassword}
              isLoading={isLoading}
            >
              Set New Password
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ForgotPassword;
