import { getIdToken } from "./auth";
import { VideoType } from "../typings/channel";
import moment from "moment";

const THUMBNAIL_BASE_URL = "https://image.mux.com";

export const getHeader = () => {
  return {
    headers: {
      "Content-type": "application/json;charset=utf-8",
      Authorization: `${getIdToken()}`,
    },
  };
};

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const toSlug = (name: string) => {
  return name
    .trim()
    .toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-");
};

export const updateSlugInUrl = (url: string, slug: string | undefined) => {
  return url.replace(":slug", slug || "");
};

export const drPrefix = (str: string) => {
  return "Dr " + str.replace(/Dr[.]?/i, "").trim();
};

export const thumbnail = (video: VideoType, list = false) => {
  let thumbnail = "";
  if (video) {
    const link = video.short_video_link || video.video_link;
    if (!video.thumbnail) {
      if (link && link.includes("mux.com")) {
        const playbackId = new URL(link).pathname.split("/")[1];
        thumbnail = `${THUMBNAIL_BASE_URL}/${playbackId}/thumbnail.jpg?width=400&height=220`;
      }
    } else if (list) {
      thumbnail = video.thumbnail.replace("original", "small");
    } else {
      thumbnail = video.thumbnail.replace("original", "medium");
    }
  }
  return thumbnail;
};

export const secondsToTime = (duration: number) => {
  const seconds = Math.floor(duration % 60);
  const minutes = Math.floor((duration / 60) % 60);
  const hours = Math.floor((duration / (60 * 60)) % 24);

  const h = hours > 0 ? String(hours).padStart(2, "0") + "h " : "";
  const m = minutes > 0 ? String(minutes).padStart(2, "0") + "m " : "";
  const s = seconds > 0 ? String(seconds).padStart(2, "0") + "s " : "";
  return h + m + s;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const localStorageSetItem = (key: string, value: string | string[]) => {
  const val = typeof value === "string" ? value : JSON.stringify(value);
  return localStorage.setItem(key, val);
};

export const localStorageGetItem = (key: string) => {
  return localStorage.getItem(key);
};

export const toTitleCase = (text: string) => {
  return text.toLowerCase().replace(/(?<!\S)\S/g, (match) => match.toUpperCase());
};

export const lineBreak = (text: string, size: number) => {
  const regex = new RegExp(`.{${size}}\\w*|.*`, "g");
  return (
    text
      .match(regex)
      ?.filter((line) => line.length)
      .map((line) => line.trim()) || []
  );
};

export const cleanObject = (obj: any) => {
  Object.keys(obj).map((k) =>
    [undefined, null, ""].includes(obj[k]) || obj[k]?.length === 0
      ? delete obj[k]
      : false,
  );
  return obj;
};

export const formatDate = (date: any, withTime = false) => {
  const time = withTime ? "h:mm A" : "";
  return date ? moment(date).format(`ddd MMM D, YYYY ${time}`) : "";
};

export const isEventModule = () => window.location.pathname.split("/")[1] === "events";
