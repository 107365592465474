import React from "react";
import { useEffect, useState } from "react";
import { Box, Card, Stack } from "@mui/material";
// import Select from "components/Core/Inputs/Select";
// import Scrollbar from "components/Core/Scrollbar/Scrollbar";
import { LoadingButton } from "@mui/lab";
import MultiSelect from "components/Core/Inputs/MultiSelect";
import { Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Pie } from "react-chartjs-2";
import { useCurrentState } from "context/global";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { getAccount, getAccountsDropdown, getSubscribersReports } from "api/api";

// import {
//   createColumnHelper,
//   getCoreRowModel,
//   useReactTable,
// } from "@tanstack/react-table";

// import Table from "components/Core/Table/SubscribersReportTable";

type detailsType = {
  account_id: number;
  clinicSubscribers: any[];
  physiciansAndApps: any[];
  regionSubscribers: any[];
  tagSubscribers: any[];
};

export default function SubscribersReportsCard() {
  const { globalState } = useCurrentState();
  const [accountsDropdown, setAccountsDropdown] = useState<any[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any[]>([]);
  // const [total, setTotal] = useState(0);

  const [isShowingDetails, setIsShowingDetails] = useState(false);
  const [details, setDetails] = useState<detailsType>({
    account_id: 0,
    clinicSubscribers: [],
    physiciansAndApps: [],
    regionSubscribers: [],
    tagSubscribers: [],
  });

  // const columnHelper = createColumnHelper<any>();

  const fetchData = async () => {
    if (selectedAccounts.length === 0) {
      alert("Please select an account");
      return;
    }
    setLoading(true);

    const data: any = await getSubscribersReports(selectedAccounts);

    const groupedData: any = {};

    Object.keys(data.subscribersReport).forEach((key) => {
      data.subscribersReport[key].forEach((item: any) => {
        const accountId = item.account_id;

        if (!groupedData[accountId]) {
          groupedData[accountId] = {
            account_id: accountId,
            clinicSubscribers: [],
            physiciansAndApps: [],
            regionSubscribers: [],
            tagSubscribers: [],
          };
        }

        if (key === "clinicSubscribers") {
          groupedData[accountId].clinicSubscribers.push({
            clinic_id: item.clinic_id,
            name: item.name,
            subscriber_count: item.subscriber_count,
            physicians: item.physicians,
            apps: item.apps,
            pd: item.pd,
            rn: item.rn,
            na: item.other,
          });
          groupedData[accountId].clinicSubscribers.sort(
            (a: any, b: any) => b.subscriber_count - a.subscriber_count,
          );
        } else if (key === "physiciansAndAPPs") {
          groupedData[accountId].physiciansAndApps.push({
            name: item.name,
            physicians: item.physicians,
            apps: item.apps,
            pd: item.pd,
            rn: item.rn,
            na: item.other,
          });
        } else if (key === "regionSubscribers") {
          groupedData[accountId].regionSubscribers.push({
            region: item.region,
            subscriber_count: item.subscriber_count,
            physicians: item.physicians,
            apps: item.apps,
            pd: item.pd,
            rn: item.rn,
            na: item.other,
          });
          groupedData[accountId].regionSubscribers.sort(
            (a: any, b: any) => b.subscriber_count - a.subscriber_count,
          );
        } else if (key === "tagSubscribers") {
          groupedData[accountId].tagSubscribers.push({
            tag_id: item.tag_id,
            tag: item.tag,
            subscriber_count: item.subscriber_count,
            physicians: item.physicians,
            apps: item.apps,
            pd: item.pd,
            rn: item.rn,
            na: item.other,
          });
          groupedData[accountId].tagSubscribers.sort(
            (a: any, b: any) => b.subscriber_count - a.subscriber_count,
          );
        }
      });
    });

    setReportData(Object.values(groupedData));
    // setTotal(Object.values(groupedData).length);

    setLoading(false);
  };

  // const columns: any = [
  //   columnHelper.accessor("Expand", {
  //     id: "expand",
  //     header: "",
  //     cell: ({ row }: any) => {
  //       return (
  //         <IconButton
  //           onClick={() => row.toggleExpanded()}
  //           size="small"
  //           style={{ padding: 0 }}
  //         >
  //           {row.getIsExpanded() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
  //         </IconButton>
  //       );
  //     },
  //   }),
  //   columnHelper.accessor("Account", {
  //     id: "account",
  //     header: "Account",
  //     cell: ({ row }: any) => {
  //       return row.original.physiciansAndApps[0].name;
  //     },
  //   }),
  //   columnHelper.accessor("Physicians", {
  //     id: "physicians",
  //     header: "Physicians",
  //     cell: ({ row }: any) => {
  //       return row.original.physiciansAndApps[0].physicians;
  //     },
  //   }),
  //   columnHelper.accessor("Apps", {
  //     id: "apps",
  //     header: "Apps",
  //     cell: ({ row }: any) => {
  //       return row.original.physiciansAndApps[0].apps;
  //     },
  //   }),
  //   columnHelper.accessor("Total", {
  //     id: "total",
  //     header: "Total",
  //     cell: ({ row }: any) => {
  //       return (
  //         Number(row.original.physiciansAndApps[0].apps) +
  //         Number(row.original.physiciansAndApps[0].physicians)
  //       );
  //     },
  //   }),
  // ];

  // const table = useReactTable({
  //   data: reportData,
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  // });

  useEffect(() => {
    async function fetchDropdown() {
      const account: any = await getAccount();
      //if ideology health account, show all accounts
      if (account.slug === "ideology-health") {
        const accounts: any = await getAccountsDropdown();
        const transformedAccounts = accounts.accounts.map((account: any) => ({
          label: account.name,
          value: account.id,
        }));
        transformedAccounts.push({
          label: "IDEOlogy Health",
          value: "00000000-0000-0000-0000-000000000000",
        });
        setAccountsDropdown(transformedAccounts);
        //if not ideology health account, show only that account
      } else {
        setAccountsDropdown([{ label: account.name, value: account.id }]);
      }
    }
    fetchDropdown();
  }, [selectedAccounts]);

  const handleShowingDetails = (item: any) => {
    setIsShowingDetails(!isShowingDetails);
    setDetails(item);
  };

  return (
    <Box>
      <Card>
        <Stack direction={"row"} justifyContent={"space-between"} p={2}>
          <Stack direction={"row"} gap={2}>
            {/* {reportType && ( */}
            <Box width={400}>
              {/* <Select
            id="account"
            label="Account"
            name="Account"
            options={accountsDropdown}
            value={selectedAccount}
            onChange={(val: string) => setSelectedAccount(val)}
          /> */}
              <MultiSelect
                id={"Accounts"}
                labelId={"Accounts"}
                label={"Accounts"}
                options={accountsDropdown}
                selectValue={selectedAccounts}
                setSelectValue={(values) => {
                  setSelectedAccounts(values);
                }}
                placeholder="Account"
                showChips={false}
                sortSelected={false}
              />
            </Box>
            <Box>
              <LoadingButton
                size="large"
                variant="contained"
                onClick={fetchData}
                loading={loading}
              >
                Get Report
              </LoadingButton>
            </Box>
          </Stack>
        </Stack>

        {/* <Scrollbar>
          <Table table={table} totalCount={total} rowsPerPage={100} />
        </Scrollbar> */}
      </Card>

      {!isShowingDetails && (
        <Box
          sx={{ marginTop: "20px" }}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {reportData.map((item: any) => {
            return (
              <Card
                key={item.account_id}
                sx={{
                  width: "30%",
                  marginBottom: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  boxShadow: 3,
                }}
                onClick={() => handleShowingDetails(item)}
              >
                <Grid container direction="column" gap={1}>
                  <Grid item>
                    <Typography align="center" variant="h4" sx={{ padding: "10px" }}>
                      {item.physiciansAndApps[0]?.name}
                    </Typography>
                  </Grid>
                  <Grid container item xs={4} justifyContent="space-between">
                    <Card
                      sx={{
                        backgroundColor: "#f0f8ff",
                        padding: "5px",
                        width: "48%",
                      }}
                    >
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{ color: "grey", padding: "4px" }}
                      >
                        Total:
                      </Typography>
                      <Typography align="left" variant="h4" sx={{ padding: "4px" }}>
                        {Number(item.physiciansAndApps[0]?.apps) +
                          Number(item.physiciansAndApps[0]?.physicians) +
                          Number(item.physiciansAndApps[0]?.pd) +
                          Number(item.physiciansAndApps[0]?.rn) +
                          Number(item.physiciansAndApps[0]?.na)}
                      </Typography>
                    </Card>
                    <Card
                      sx={{ backgroundColor: "#f0f8ff", padding: "5px", width: "48%" }}
                    >
                      <Pie
                        data={{
                          labels: ["APPs", "Physicians", "PD", "RN", "Other"],
                          datasets: [
                            {
                              label: "",
                              backgroundColor: [
                                globalState?.primaryColor,
                                globalState?.secondaryColor,
                                "#add8e6",
                                "#c7d4c9",
                                "#d9bdcc",
                                "#3cba9f",
                                "#e8c3b9",
                              ],
                              data: [
                                Number(item.physiciansAndApps[0]?.apps),
                                Number(item.physiciansAndApps[0]?.physicians),
                                Number(item.physiciansAndApps[0]?.pd),
                                Number(item.physiciansAndApps[0]?.rn),
                                Number(item.physiciansAndApps[0]?.na),
                              ],
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          plugins: {
                            legend: {
                              position: "bottom",
                              align: "center",
                              labels: {
                                usePointStyle: true,
                              },
                            },
                          },
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid container item xs={4} justifyContent="space-between">
                    <Card
                      sx={{
                        backgroundColor: "#fafad2",
                        padding: "5px",
                        width: "48%",
                        // aspectRatio: "1 / 1",
                      }}
                    >
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{ color: "grey", padding: "4px" }}
                      >
                        APPs:
                      </Typography>
                      <Typography align="left" variant="h4" sx={{ padding: "4px" }}>
                        {item.physiciansAndApps[0]?.apps}
                      </Typography>
                    </Card>
                    <Card
                      sx={{ backgroundColor: "#e6e6fa", padding: "5px", width: "48%" }}
                    >
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{ color: "grey", padding: "4px" }}
                      >
                        Physicians:
                      </Typography>
                      <Typography align="left" variant="h4" sx={{ padding: "4px" }}>
                        {item.physiciansAndApps[0]?.physicians}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid container item xs={4} justifyContent="space-between">
                    <Card
                      sx={{
                        backgroundColor: "#a3c5ea",
                        padding: "5px",
                        width: "48%",
                        // aspectRatio: "1 / 1",
                      }}
                    >
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{ color: "grey", padding: "4px" }}
                      >
                        PD:
                      </Typography>
                      <Typography align="left" variant="h4" sx={{ padding: "4px" }}>
                        {item.physiciansAndApps[0]?.pd}
                      </Typography>
                    </Card>
                    <Card
                      sx={{ backgroundColor: "#c6e0d3", padding: "5px", width: "48%" }}
                    >
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{ color: "grey", padding: "4px" }}
                      >
                        RN:
                      </Typography>
                      <Typography align="left" variant="h4" sx={{ padding: "4px" }}>
                        {item.physiciansAndApps[0]?.rn}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid container item xs={4} justifyContent="space-between">
                    <Card
                      sx={{
                        backgroundColor: "#f2c7d2",
                        padding: "5px",
                        width: "48%",
                        // aspectRatio: "1 / 1",
                      }}
                    >
                      <Typography
                        align="left"
                        variant="subtitle1"
                        sx={{ color: "grey", padding: "4px" }}
                      >
                        Other:
                      </Typography>
                      <Typography align="left" variant="h4" sx={{ padding: "4px" }}>
                        {item.physiciansAndApps[0]?.na}
                      </Typography>
                    </Card>
                  </Grid>
                </Grid>
              </Card>
            );
          })}
        </Box>
      )}

      {isShowingDetails && (
        <>
          <Box display="flex" flexDirection="row" gap={2}>
            <Card
              sx={{
                marginTop: "20px",
                padding: "20px",
                boxShadow: 3,
                width: "70%",
              }}
            >
              <Stack direction={"row"} p={2}>
                <ArrowBackIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIsShowingDetails(false)}
                />
              </Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ marginBottom: "20px" }}
              >
                <Typography variant="h5" gutterBottom>
                  {details.physiciansAndApps[0]?.name}
                </Typography>
                <Typography variant="body1">
                  Physicians: {details.physiciansAndApps[0]?.physicians}
                </Typography>
                <Typography variant="body1">
                  Apps: {details.physiciansAndApps[0]?.apps}
                </Typography>
              </Box>
              <Divider />
              <Grid
                container
                justifyContent="space-between"
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <Grid item xs={2}>
                  <Typography variant="h6">Clinics</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    Physicians
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    APPs
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    PD
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    RN
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    NA
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h6" align="right">
                    Count
                  </Typography>
                </Grid>
              </Grid>
              <List>
                {details.clinicSubscribers.map((clinic: any) => {
                  return (
                    <Grid
                      container
                      key={clinic.clinic_id}
                      justifyContent="space-between"
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="body2">{clinic.name}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {clinic.physicians}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {clinic.apps}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {clinic.pd}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {clinic.rn}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {clinic.na}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {clinic.subscriber_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
              <Divider />
              <Grid
                container
                justifyContent="space-between"
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <Grid item xs={2}>
                  <Typography variant="h6">Regions</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    Physicians
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    APPs
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    PD
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    RN
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    NA
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h6" align="right">
                    Count
                  </Typography>
                </Grid>
              </Grid>
              <List>
                {details.regionSubscribers.map((region: any) => {
                  return (
                    <Grid
                      container
                      key={region.region}
                      justifyContent="space-between"
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="body2">{region.region}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {region.physicians}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {region.apps}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {region.pd}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {region.rn}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {region.na}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {region.subscriber_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
              <Divider />
              <Grid
                container
                justifyContent="space-between"
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                <Grid item xs={2}>
                  <Typography variant="h6">Tags</Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    Physicians
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    APPs
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    PD
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    RN
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" align="right">
                    NA
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="h6" align="right">
                    Count
                  </Typography>
                </Grid>
              </Grid>
              <List>
                {details.tagSubscribers.map((tag: any) => {
                  return (
                    <Grid
                      container
                      key={tag.tag_id}
                      justifyContent="space-between"
                      sx={{ marginBottom: "20px" }}
                    >
                      <Grid item xs={2}>
                        <Typography variant="body2">{tag.tag}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {tag.physicians}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {tag.apps}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {tag.pd}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {tag.rn}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {tag.na}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography variant="body2" align="right">
                          {tag.subscriber_count}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </List>
            </Card>
            <Card
              sx={{
                marginTop: "20px",
                padding: "20px",
                boxShadow: 3,
                width: "30%",
              }}
            >
              <Box sx={{ marginBottom: "30px" }}>
                <Pie
                  data={{
                    labels: details.clinicSubscribers.map((clinic: any) => clinic.name),
                    datasets: [
                      {
                        label: "",
                        backgroundColor: [
                          globalState?.primaryColor,
                          globalState?.secondaryColor,
                          "#3cba9f",
                          "#e8c3b9",
                          "#8e5ea2",
                          "#3e95cd",
                          "#2f4f4f",
                          "#800000",
                          "#008080",
                          "#808000",
                        ],
                        data: [
                          ...details.clinicSubscribers.map(
                            (clinic: any) => clinic.subscriber_count,
                          ),
                        ],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "bottom",
                        align: "center",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box sx={{ marginBottom: "30px" }}>
                {" "}
                <Pie
                  data={{
                    labels: details.regionSubscribers.map((region: any) => region.region),
                    datasets: [
                      {
                        label: "",
                        backgroundColor: [
                          globalState?.primaryColor,
                          globalState?.secondaryColor,
                          "#3cba9f",
                          "#e8c3b9",
                          "#8e5ea2",
                          "#3e95cd",
                          "#2f4f4f",
                          "#800000",
                          "#008080",
                          "#808000",
                        ],
                        data: [
                          ...details.regionSubscribers.map(
                            (region: any) => region.subscriber_count,
                          ),
                        ],
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "bottom",
                        align: "center",
                        labels: {
                          usePointStyle: true,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Pie
                data={{
                  labels: details.tagSubscribers.map((tag: any) => tag.tag),
                  datasets: [
                    {
                      label: "",
                      backgroundColor: [
                        globalState?.primaryColor,
                        globalState?.secondaryColor,
                        "#3cba9f",
                        "#e8c3b9",
                        "#8e5ea2",
                        "#3e95cd",
                        "#2f4f4f",
                        "#800000",
                        "#008080",
                        "#808000",
                      ],
                      data: [
                        ...details.tagSubscribers.map((tag: any) => tag.subscriber_count),
                      ],
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "bottom",
                      align: "center",
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
              />
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
}
