import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import pages from "enums/pages";
import { v4 as uuid } from "uuid";
import Scrollbar from "components/Core/Scrollbar";
import Table from "components/Core/Table/Table";
import Filters from "./Filters";
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import RowSelection from "components/Core/Table/RowSelection";
import { getModerators } from "api/eventApi";
import MoreOptions from "components/Core/MoreOptions";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { localStorageGetItem } from "helpers/utils";
import useQuery from "hooks/useQuery";

export default function Moderators() {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<any>();
  const [moderators, setModerators] = React.useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { params } = useQuery();

  const limit = 20;
  const page = +params.page || 0;
  const search = params.search || "";
  const eventFilter = params.event || "all";

  const getModeratorsData = async (
    search: string,
    eventFilter: any,
    sorting: any,
    page: number,
    limit: number,
  ) => {
    const sort = sorting.length
      ? `${sorting[0].id}:${sorting[0].desc ? "desc" : "asc"}`
      : "";

    const filter: any = { search, eventFilter, sort };
    const data: any = await getModerators("", filter, limit, page);
    console.log(data);
    setModerators(data.rows);
    setTotalCount(data.count);
  };

  useEffect(() => {
    getModeratorsData(search, eventFilter, sorting, page, limit);
  }, [search, eventFilter, sorting, page, limit]);

  const [columnVisibility, setColumnVisibility] = useState<any>({});

  const columns: any = [
    {
      id: "select",
      header: ({ table }: any) => (
        <RowSelection
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }: any) => (
        <RowSelection
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    },
    columnHelper.accessor("full_name", {
      id: "full_name",
      header: "Full Name",
      cell: ({ row }: any) => {
        const eventLink = `${pages.MODERATOR_PAGE.replace(":id", row.original.id)}`;
        return (
          <span
            style={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => navigate(eventLink)} // Navigate to the event page
          >
            {row.original.full_name}
          </span>
        );
      },
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: "Email",
      cell: ({ row }: any) => {
        return row.original.email;
      },
    }),
    columnHelper.accessor("phone", {
      id: "phone",
      header: "Phone Number",
      cell: ({ row }: any) => {
        return row.original.phone;
      },
    }),
    columnHelper.accessor("institution", {
      id: "institution",
      header: "Institution",
      cell: ({ row }: any) => {
        return row.original.insitution; // Got a Typo in db column: insitution
      },
    }),
    columnHelper.accessor("specialty", {
      id: "specialty",
      header: "Specialty",
      cell: ({ row }: any) => {
        return row.original.specialty;
      },
    }),
    columnHelper.accessor("headshot", {
      id: "headshot",
      header: "Headshot",
      cell: ({ row }: any) => {
        return (
          <img
            src={row.original.photo}
            alt="Headshot"
            style={{
              width: "70px",
              height: "70px",
              objectFit: "cover",
            }}
          />
        );
      },
    }),

    columnHelper.accessor("action", {
      id: "action",
      header: "",
      cell: ({ row }: any) => {
        const moderatorLink = `${pages.MODERATOR_PAGE.replace(":id", row.original.id)}`;
        return (
          <MoreOptions
            options={[
              {
                label: "Edit",
                icon: <EditIcon width={24} height={24} />,
                link: moderatorLink + "#edit",
              },
              {
                label: "Delete",
                icon: <DeleteIcon width={24} height={24} />,
                // onClick: () => confirmVideoDeletion(row.original.id, row.original.title),
              },
            ]}
          />
        );
      },
    }),
  ];

  const excludeColFromEdit: any = {};

  const visibleColums = columns
    .filter((col: any) => col.header && typeof col.header === "string")
    .filter((col: any) =>
      excludeColFromEdit[col.id] !== undefined ? excludeColFromEdit[col.id] : true,
    )
    .map((col: any) => ({ value: col.id, label: col.header }));

  // hide/show columns
  const updateColumnVisibility = useCallback(() => {
    const storedColumns = JSON.parse(
      localStorageGetItem("moderatorsTableColumns") || "[]",
    );

    if (storedColumns.length) {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(storedColumns.map((col: string) => [col, true])),
        ...Object.fromEntries(
          visibleColums
            .filter((col: any) => !storedColumns.includes(col.value))
            .map((col: any) => [col.value, false]),
        ),
      }));
    } else {
      setColumnVisibility((prev: any) => ({
        ...prev,
        ...Object.fromEntries(visibleColums.map((col: any) => [col.value, true])),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateColumnVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const table: any = useReactTable({
    data: moderators,
    columns,
    state: { sorting, columnVisibility },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container maxWidth={"xl"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Moderators
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate(pages.MODERATOR_PAGE.replace(":id", uuid()) + "?new=true");
          }}
          startIcon={<AddIcon />}
          size="large"
        >
          New Moderator
        </Button>
      </Stack>

      <Filters columns={visibleColums} updateColumnVisibility={updateColumnVisibility} />

      <Card>
        <Scrollbar>
          <Table table={table} totalCount={totalCount} rowsPerPage={limit} />
        </Scrollbar>
      </Card>
    </Container>
  );
}
