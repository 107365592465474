import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import MessageIcon from "@mui/icons-material/Message";
import { ItemProps } from "typings/Menu";
import pages from "enums/pages";
import { ROLES } from "enums/common";
import { useCurrentState } from "context/global";
import { useCurrentUser } from "context/user";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { isEventModule } from "helpers/utils";
import { useLocation } from "react-router-dom";

type OptionType = {
  [key: string]: ItemProps;
};

const options: OptionType = {
  dashboard: {
    title: "Dashboard",
    path: pages.DASHBOARD_PAGE,
    icon: <DashboardIcon />,
  },
  channels: {
    title: "Channels",
    path: pages.CHANNELS_PAGE,
    icon: <WorkspacesIcon />,
  },
  videos: {
    title: "Video Library",
    path: pages.VIDEOS_LIBRARY_PAGE,
    icon: <VideoLibraryIcon />,
    children: [
      { title: "videos", path: pages.VIDEOS_LIBRARY_PAGE },
      { title: "Video Series", path: pages.VIDEO_SERIES },
    ],
  },
  subscribers: {
    title: "Subscribers",
    path: pages.SUBSCRIBERS_PAGE,
    icon: <AccountBoxIcon />,
  },
  users: { title: "Users", path: pages.USERS_PAGE, icon: <GroupIcon /> },
  messages: {
    title: "Message",
    path: pages.MESSAGES_PAGE,
    icon: <MessageIcon />,
  },
  reports: {
    title: "Reports",
    path: pages.VIDEOS_REPORTS_PAGE,
    icon: <InsertChartIcon />,
    children: [
      { title: "videos", path: pages.VIDEOS_REPORTS_PAGE },
      { title: "Subscribers", path: pages.SUBSCRIBERS_REPORTS_PAGE },
    ],
  },
  settings: {
    title: "Settings",
    path: pages.SETTINGS_PAGE,
    icon: <SettingsIcon />,
  },
};

const eventsOptions: OptionType = {
  dashboard: {
    title: "Dashboard",
    path: pages.EVENTS_DASHBOARD_PAGE,
    icon: <DashboardIcon />,
  },
  event: {
    title: "Events",
    path: pages.EVENTS_LIST_PAGE,
    icon: <WorkspacesIcon />,
  },
  moderators: {
    title: "Moderators",
    path: pages.MODERATORS_LIST_PAGE,
    icon: <GroupIcon />,
  },
};

const MenuOptions = () => {
  const location = useLocation();
  const { globalState } = useCurrentState();
  const { user } = useCurrentUser();
  const isEvents = isEventModule();

  const isSuperAdmin = !!user?.isSuperAdmin;
  const role = globalState?.role;
  const { soundbites = true, ideology = false } = user?.adminPanelType ?? {};

  const menuOptions: ItemProps[] = [];

  if (soundbites && !isEvents) {
    if ((user?.channels?.length || 0) > 1) {
      options.channels.children = user?.channels.map((channel) => ({
        title: channel.name,
        path: pages.CHANNELS_VIDEOS_PAGE.replace(":id", channel.id),
      }));
    }

    if (role === ROLES.LITE_CHANNEL_ADMIN) {
      menuOptions.push(...[options.channels, options.videos, options.reports]);
    }

    if (role === ROLES.CHANNEL_ADMIN) {
      menuOptions.push(
        ...[options.dashboard, options.channels, options.videos, options.reports],
      );
    }

    if (isSuperAdmin || role === ROLES.ACCOUNT_ADMIN) {
      menuOptions.push(...Object.values(options));
    }
  }

  if (ideology && isEvents) {
    if (location.pathname.startsWith("/events/conference/")) {
      menuOptions.push(
        { title: "Back", path: "/events/dashboard" },
        { title: "Overview", path: `${location.pathname}#overview` },
        { title: "Tickets", path: `${location.pathname}#tickets` },
        { title: "Registrations", path: `${location.pathname}#registrations` },
        { title: "Edit", path: `${location.pathname}#edit` },
      );
    } else {
      menuOptions.push(...Object.values(eventsOptions));
    }
  }

  return menuOptions;
};

export default MenuOptions;
