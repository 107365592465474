import React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import errors from "enums/errors";
import FileUpload from "components/Core/Inputs/FileUpload";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getModerators, upsertModerator } from "api/eventApi";
import { LoadingButton } from "@mui/lab";
import { useDialog } from "context/dialog";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import pages from "enums/pages"; // Import pages enum
import { uploadImage } from "helpers/image";
import { UPLOAD_DIR } from "enums/common";

const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
  dob: Yup.string().required("Date of birth is required"),
  IDEO_speaker: Yup.boolean(),
  soundbites: Yup.boolean(),
  IDEO_SWOT: Yup.boolean(),
  IDEO_conference: Yup.boolean(),
  content_creation: Yup.boolean(),
  willing_to_travel: Yup.string(),
  preferences: Yup.object(),
});

export default function AddEditModerator() {
  const { id } = useParams();

  const navigate = useNavigate(); // Initialize navigate
  const [isLoading, setIsLoading] = useState(false);
  const { alertSuccess, alertError } = useDialog();
  const [moderatorData, setModeratorData] = useState<any>({});

  useEffect(() => {
    const fetchModeratorData = async () => {
      if (id) {
        try {
          const res: any = await getModerators(id);
          console.log(res);
          setModeratorData(res.rows[0]);
        } catch (error) {
          console.error("Error fetching event data:", error);
        }
      }
    };
    fetchModeratorData();
  }, [id]);

  const formik = useFormik({
    initialValues: {
      first_name: moderatorData?.first_name || "",
      last_name: moderatorData?.last_name || "",
      full_name: moderatorData?.full_name || "",
      credentials: moderatorData?.credentials || "",
      insitution: moderatorData?.insitution || "",
      insitution_city: moderatorData?.insitution_city || "",
      specialty: moderatorData?.specialty || "",
      region: moderatorData?.region || "",
      IDEO_speaker: moderatorData?.IDEO_speaker || false,
      soundbites: moderatorData?.soundbites || false,
      IDEO_SWOT: moderatorData?.IDEO_SWOT || false,
      IDEO_conference: moderatorData?.IDEO_conference || false,
      content_creation: moderatorData?.content_creation || false,
      willing_to_travel: moderatorData?.willing_to_travel || "",
      IDEO_contact: moderatorData?.IDEO_contact || "",
      address: moderatorData?.address || "",
      email: moderatorData?.email || "",
      phone: moderatorData?.phone || "",
      CV: moderatorData?.CV || "",
      preferences: moderatorData?.preferences || {},
      preferencesString: Object.entries(moderatorData?.preferences || {})
        .map(([key, value]) => `${key}: ${value}`)
        .join("; "),
      dob: moderatorData?.dob || "",
      headshotFile: null,
      photo: moderatorData?.photo || "",
    },
    enableReinitialize: true, // Enable reinitialization when moderatorData changes
    validationSchema: validationSchema, // Add your validation schema here
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let photo = "";
        if (values.headshotFile) {
          photo = await uploadImage(values.headshotFile, id, UPLOAD_DIR.MODERATORS);
          console.log("Photo uploaded:", photo);
          values.photo = photo;
        }

        await upsertModerator(values, id); // Replace with your API call for updating/creating a moderator
        alertSuccess("Moderator updated successfully");
        navigate(pages.MODERATORS_LIST_PAGE); // Redirect to the moderators list
      } catch (error) {
        console.error("Error updating moderator:", error);
        alertError("Failed to update moderator");
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <Container style={{ padding: 0 }} maxWidth={"xl"}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {/* First Name and Last Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              label="First Name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              label="Last Name"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>

          {/* Full Name and Credentials */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="full_name"
              name="full_name"
              label="Full Name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.full_name && Boolean(formik.errors.full_name)}
              helperText={formik.touched.full_name && formik.errors.full_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="credentials"
              name="credentials"
              label="Credentials"
              value={formik.values.credentials}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.credentials && Boolean(formik.errors.credentials)}
              helperText={formik.touched.credentials && formik.errors.credentials}
            />
          </Grid>

          {/* Institution and Institution City */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="insitution"
              name="insitution"
              label="Institution"
              value={formik.values.insitution}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.insitution && Boolean(formik.errors.insitution)}
              helperText={formik.touched.insitution && formik.errors.insitution}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="insitution_city"
              name="insitution_city"
              label="Institution City"
              value={formik.values.insitution_city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.insitution_city && Boolean(formik.errors.insitution_city)
              }
              helperText={formik.touched.insitution_city && formik.errors.insitution_city}
            />
          </Grid>

          {/* Specialty and Region */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="specialty"
              name="specialty"
              label="Specialty"
              value={formik.values.specialty}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.specialty && Boolean(formik.errors.specialty)}
              helperText={formik.touched.specialty && formik.errors.specialty}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="region"
              name="region"
              label="Region"
              value={formik.values.region}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.region && Boolean(formik.errors.region)}
              helperText={formik.touched.region && formik.errors.region}
            />
          </Grid>

          {/* Boolean Fields */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="IDEO_speaker"
                  name="IDEO_speaker"
                  checked={formik.values.IDEO_speaker}
                  onChange={formik.handleChange}
                />
              }
              label="IDEO Speaker"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="soundbites"
                  name="soundbites"
                  checked={formik.values.soundbites}
                  onChange={formik.handleChange}
                />
              }
              label="Soundbites"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="IDEO_SWOT"
                  name="IDEO_SWOT"
                  checked={formik.values.IDEO_SWOT}
                  onChange={formik.handleChange}
                />
              }
              label="IDEO SWOT"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="IDEO_conference"
                  name="IDEO_conference"
                  checked={formik.values.IDEO_conference}
                  onChange={formik.handleChange}
                />
              }
              label="IDEO Conference"
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="content_creation"
                  name="content_creation"
                  checked={formik.values.content_creation}
                  onChange={formik.handleChange}
                />
              }
              label="Content Creation"
            />
          </Grid>

          {/* Willing to Travel (TextField) */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="willing_to_travel"
              name="willing_to_travel"
              label="Willing to Travel"
              value={formik.values.willing_to_travel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.willing_to_travel &&
                Boolean(formik.errors.willing_to_travel)
              }
              helperText={
                formik.touched.willing_to_travel && formik.errors.willing_to_travel
              }
            />
          </Grid>

          {/* IDEO Contact (TextField) */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="IDEO_contact"
              name="IDEO_contact"
              label="IDEO Contact"
              value={formik.values.IDEO_contact}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.IDEO_contact && Boolean(formik.errors.IDEO_contact)}
              helperText={formik.touched.IDEO_contact && formik.errors.IDEO_contact}
            />
          </Grid>

          {/* Address and Email */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="address"
              name="address"
              label="Address"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>

          {/* Phone and DOB */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="phone"
              name="phone"
              label="Phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="dob"
              name="dob"
              label="Date of Birth"
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.dob && Boolean(formik.errors.dob)}
              helperText={formik.touched.dob && formik.errors.dob}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="cv"
              name="CV"
              label="CV"
              value={formik.values.CV}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.CV && Boolean(formik.errors.CV)}
              helperText={formik.touched.CV && formik.errors.CV}
            />
          </Grid>

          <Grid item xs={12}>
            <FileUpload
              id="headshot"
              label="Headshot"
              name="headshot"
              accept="image"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files !== null && event.target?.files?.length > 0) {
                  formik.setFieldValue("headshotFile", event.target.files[0]);
                  const size = event.target.files[0].size;
                  if (size / 1024 <= 5 * 1024) {
                    formik.setErrors({
                      headshotFile: errors.VALIDATION.THUMBNAIL_FILE.SIZE,
                    });
                  }
                }
              }}
              onDrop={(event: React.DragEvent<HTMLElement>) => {
                formik.setFieldValue("headshotFile", event.dataTransfer.files[0]);
                const size = event.dataTransfer?.files[0]?.size ?? 0;
                if (size / 1024 <= 5 * 1024) {
                  formik.setErrors({
                    headshotFile: errors.VALIDATION.THUMBNAIL_FILE.SIZE,
                  });
                }
              }}
              imageButton
              image={{
                url: formik.values.photo,
              }}
              error={formik.touched.headshotFile && Boolean(formik.errors.headshotFile)}
              helperText={formik.touched.headshotFile && formik.errors.headshotFile}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="preferences"
              name="preferences"
              label="Preferences (e.g., flight: southwest; hotel: marriot)"
              value={formik.values.preferencesString || ""}
              onChange={(e) => {
                const value = e.target.value;
                formik.setFieldValue("preferencesString", value);

                // Parse the input into a JSON object
                const preferences = value
                  .split(";")
                  .reduce<Record<string, string>>((acc, item) => {
                    const [key, val] = item.split(":").map((str) => str.trim());
                    if (key && val) acc[key] = val;
                    return acc;
                  }, {});
                formik.setFieldValue("preferences", preferences);
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.preferencesString &&
                Boolean(formik.errors.preferencesString)
              }
              helperText={
                formik.touched.preferencesString && formik.errors.preferencesString
              }
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            loading={isLoading}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}
