import { getHeader } from "helpers/utils";
import { apiCall, prepareQueryParams } from "./api";
import { ObjectType } from "typings/common";

const API_URL = process.env.REACT_APP_IDEOLOGY_API_URL;

export const getEvents = (id: string, filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/events`;
  if (id)
    url += prepareQueryParams({
      id: id,
    });
  else {
    url += prepareQueryParams({
      ...filter,
      page: page || 0,
      limit: limit || 10,
    });
  }

  return apiCall("get", url, getHeader());
};

export const upsertEvent = (data: ObjectType, id?: string) => {
  let url = `${API_URL}/events`;
  if (id) url += `/${id}`;
  console.log("url", url);
  return apiCall("post", url, {
    data,
    ...getHeader(),
  });
};

export const getUsers = (filter?: any, limit?: number, page?: number) => {
  let url = `${API_URL}/users`;
  url += prepareQueryParams({
    ...filter,
    page: page || 0,
    limit: limit || 10,
  });
  return apiCall("get", url, getHeader());
};

export const getUser = () => {
  let url = `${API_URL}/user`;
  return apiCall("get", url, getHeader());
};

export const upsertUser = (data: any) =>
  apiCall("post", `${API_URL}/users`, {
    data,
    ...getHeader(),
  });

export const inviteUser = (email: string, role: string) =>
  apiCall("post", `${API_URL}/invite`, {
    data: {
      email,
      role,
    },
    ...getHeader(),
  });

export const getModerators = (
  id: string,
  filter?: any,
  limit?: number,
  page?: number,
) => {
  let url = `${API_URL}/moderators`;
  if (id)
    url += prepareQueryParams({
      id: id,
    });
  else {
    url += prepareQueryParams({
      ...filter,
      page: page || 0,
      limit: limit || 10,
    });
  }
  return apiCall("get", url, getHeader());
};

export const upsertModerator = (data: ObjectType, id?: string) => {
  let url = `${API_URL}/moderator`;
  if (id) url += `/${id}`;
  return apiCall("post", url, {
    data,
    ...getHeader(),
  });
};
