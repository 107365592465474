import { useEffect, useState } from "react";
import React from "react";
import { Box, Button, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import InputField from "components/Core/Inputs/Input";
import MultiSelect from "components/Core/Inputs/MultiSelect";
import Select from "components/Core/Inputs/Select";
import useQuery from "hooks/useQuery";

import CancelIcon from "@mui/icons-material/Cancel";
import { localStorageGetItem, localStorageSetItem } from "helpers/utils";

interface Props {
  columns: any;
  updateColumnVisibility: () => void;
}

const Filters = ({ columns, updateColumnVisibility }: Props) => {
  const { params, updateQueryParams, deleteQueryParams } = useQuery();

  const [tableColumns, setTableColumns] = useState<string[]>([]);
  const [search, setSearch] = useState(params.search || "");

  const clearSearch = () => {
    setSearch("");
    deleteQueryParams("search");
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // Perform any necessary logic here
    handleSearch(); // Call the handleSearch function
  };

  const handleSearch = () => {
    if (search) {
      updateQueryParams({ search, page: 0 });
    }
  };

  const handleEventFilter = (val: any) => {
    updateQueryParams({ event: val, page: 0 });
  };

  useEffect(() => {
    const storedColumns = JSON.parse(localStorageGetItem("eventsTableColumns") || "[]");
    if (storedColumns.length) {
      setTableColumns(storedColumns);
    } else {
      setTableColumns(columns.map((col: any) => col.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12} sm={8} display={"flex"} alignItems="center">
          <form onSubmit={handleSubmit}>
            <Stack gap={1} flexDirection={"row"} alignItems="center">
              <InputField
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search conference..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={clearSearch} edge="end">
                        {search.length > 0 && <CancelIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                size="large"
                onClick={handleSearch}
                type="submit"
              >
                Search
              </Button>
            </Stack>
          </form>
        </Grid>

        <Grid item xs={6} sm={2} display={"flex"} alignItems="center">
          <Select
            label="Event Filter"
            defaultValue={params.event || "all"}
            options={[
              { label: "All", value: "all" },
              { label: "Upcoming", value: "upcoming" },
              { label: "Completed", value: "completed" },
            ]}
            onChange={handleEventFilter}
          />
        </Grid>

        <Grid item xs={6} sm={2} display={"flex"} pl={1}>
          <MultiSelect
            id={"tableColumns"}
            labelId={"tableColumns"}
            label={"Edit Columns"}
            options={columns}
            selectValue={tableColumns}
            setSelectValue={(values) => {
              localStorageSetItem("eventTableColumns", values);
              setTableColumns(values);
              updateColumnVisibility();
            }}
            placeholder="Edit Columns"
            showChips={false}
            sortSelected={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Filters;
