import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { MobileDateTimePickerProps } from "@mui/x-date-pickers/MobileDateTimePicker/MobileDateTimePicker.types";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";

const MobilePicker = styled(MobileDateTimePicker)`
  width: 100%;
`;

interface Props {
  error?: boolean;
  helperText?: React.ReactNode;
  tooltip?: string;
  disablePast?: boolean;
  shouldDisableDate?: (day: any) => boolean;
  hasPredefinedOption?: boolean;
  dateChange?: (date: any) => void;
}

const DateTimePicker = (Props: Props & MobileDateTimePickerProps<any>) => {
  const [predefinedOption, setPredefinedOption] = useState("");

  const handlePredefinedChange = (event: any) => {
    const value = event.target.value as string;
    setPredefinedOption(value);

    let newDate = null;
    const monthMapping: { [key: string]: number } = {
      "3_months": 3,
      "6_months": 6,
      "9_months": 9,
      "12_months": 12,
      "24_months": 24,
    };
    if (monthMapping[value]) {
      newDate = moment().add(monthMapping[value], "months");
    }
    //@ts-ignore
    Props.dateChange(newDate);
  };
  return (
    <Box mb={2}>
      {Props.label && (
        <Stack flexDirection="row" gap={1} style={{ marginBottom: 5 }}>
          <InputLabel error={Props?.error}>{Props.label}</InputLabel>
          {Props?.tooltip && (
            <Tooltip title={Props.tooltip}>
              <InfoIcon color="info" />
            </Tooltip>
          )}
        </Stack>
      )}
      {Props.hasPredefinedOption && (
        <Select
          value={predefinedOption}
          onChange={handlePredefinedChange}
          displayEmpty
          fullWidth
          style={{ marginTop: 10 }}
        >
          <MenuItem value="">
            <em>Quick Pick Time / None</em>
          </MenuItem>
          <MenuItem value="3_months">3 months later</MenuItem>
          <MenuItem value="6_months">6 months later</MenuItem>
          <MenuItem value="9_months">9 months later</MenuItem>
          <MenuItem value="12_months">12 months later</MenuItem>
          <MenuItem value="24_months">24 months later</MenuItem>
        </Select>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MobilePicker
          {...Props}
          label=""
          defaultValue={Props.defaultValue ? moment(Props.defaultValue) : null}
          disablePast={Props.disablePast}
          shouldDisableDate={Props.shouldDisableDate}
        />
      </LocalizationProvider>
      {Props.helperText && (
        <FormHelperText error={Props.error}>{Props.helperText}</FormHelperText>
      )}
    </Box>
  );
};

export default DateTimePicker;
